<template>
  <div>
    <!--    <el-checkbox v-model="checked" @change="setDelayNotifyTag">今日不再提示</el-checkbox>-->
    <!--    <el-link type="primary" @click="open">(逾期)</el-link>-->
    <slot name="content"></slot>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" :title="dialogTitle" width="90%">
      <div style="display: flex;flex-direction: column;">
        <div style="margin-bottom: 8px;line-height:1">
          <el-select v-model="searchCondition.group_id" @change="showGroupDelay" size="mini" :clearable="true">
            <el-option v-for="(item,index) in options" :key="index" :label="item.group_name" :value="item.group_id">
              <span>{{item.group_name}}</span>
              <span>({{item.num}})</span>
            </el-option>
          </el-select>
        </div>
        <!--   table列表     -->
        <div class="default-table">
          <el-table :data="dataList" @sort-change="changeTableSort" highlight-current-row border>
            <el-table-column
                slot="second-column"
                width="64"
                label="序号" align="center" fixed="left">
              <template slot-scope="scope">
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="group_id" label="组别" min-width="100" align="center" show-overflow-tooltip
                slot="first-column">
              <template slot-scope="scope">
                <span>{{ scope.row.group_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="nickname" label="红人昵称" min-width="120" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <artist-nickname :info="scope.row" />
              </template>
            </el-table-column>
            <el-table-column prop="executor" label="AE" min-width="100" align="center" show-overflow-tooltip />
            <el-table-column prop="platform_code" label="平台" min-width="100" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.platform_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="brand" label="品牌" min-width="100" align="center" show-overflow-tooltip />
            <el-table-column prop="product_title" label="产品名称" min-width="140" header-align="center" align="left"
                show-overflow-tooltip />
            <el-table-column prop="business_type" label="商务类型" min-width="80" align="center" sortable="custom"
                show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.business_type_alias }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="coa_mode" label="合作形式" min-width="80" align="center" show-overflow-tooltip />
            <el-table-column prop="discount" label="优惠/福利" min-width="120" header-align="center" align="left"
                show-overflow-tooltip />
            <el-table-column prop="report" label="报备" min-width="100" align="center" show-overflow-tooltip />
            <el-table-column prop="status" label="状态" min-width="80" align="center" sortable="custom">
              <template slot-scope="scope">
                <span>{{ scope.row.status_alias }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="release_date" label="发布时间" min-width="120" align="center" sortable="custom"
                show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.status === 'delay' ? scope.row.last_dates : scope.row.release_date }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="theme_content" label="内容主题" min-width="200" header-align="center" align="left"
                show-overflow-tooltip />
            <el-table-column prop="rate_progress" label="执行进度" min-width="80" align="center" fixed="right">
              <template slot-scope="scope">
                <el-link type="primary" @click="execStep(scope.row)" v-if="canExec">{{
                    scope.row.rate_progress
                  }}
                </el-link>
                <span v-else>{{ scope.row.rate_progress }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div>
            <Pagination
                v-show="total>0"
                :total="total"
                :page.sync="pagingData.page"
                :limit="pagingData.page_size"
                @pagination="getDelayList" />
          </div>
        </div>
        <div slot="footer">
          <el-row>
            <el-col :span="24" style="text-align: right">
              <el-checkbox v-model="checked" @change="setDelayNotifyTag">今日不再提示</el-checkbox>
            </el-col>
          </el-row>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApeTable from "@/components/ApeTable";
import BusinessTable from './BusinessTable'
import { mapGetters } from "vuex";

export default {
  name: "DelayNotify",
  components: {ApeTable, BusinessTable,},
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${this.searchCondition.group_name ? this.searchCondition.group_name : ''}截至今日逾期发布商务内容：${this.delay_statistic.delay_count}条`
    },
    options(){
      return this.delay_statistic.items?this.delay_statistic.items:[]
    }
  },
  props: {
    auto: {
      type: Boolean,
      default() {
        return true
      }
    },
    canExec: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      checked: false,
      loadingStatus: false,
      orderSort: {release_date: 'asc', id: 'desc'},//默认排序规则
      dataList: [],
      total: 0,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15,
        page: 1
      },
      offset: 0,
      searchCondition: {group_id: null},
      delay_statistic: {delay_count: 0, items: []},
      runTag: null
    }
  },
  // watch: {
  //   'delay_statistic.delay_count'(val) {
  //     if (val > 0) {
  //       // this.$notify.success(this.dialogTitle)
  //       this.open()
  //     } else {
  //       this.close()
  //     }
  //   }
  // },
  methods: {
    onOpen() {
      // console.log('onOpen')
      // this.$notify.info('数据加载')
      this.init()
      //显示明细
      // this.$nextTick(async () => {
      //
      // })
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
      // this.$emit('refreshData')
      sessionStorage.setItem('delay_notified', 'Y')
    },
    close() {
      this.$emit('update:visible', false)
    },
    open() {
      // console.log('open')
      this.$emit('update:visible', true)
      //已弹窗
      sessionStorage.setItem('delay_notified', 'Y')
    },
    init() {
      this.getDelayStatistic()
      this.getDelayList()
    },
    async getDelayStatistic() {
      let info = await this.$api.getBusinessPlanDelayStatistic()
      if (info) {
        this.delay_statistic = info
      }
    },
    setDelayNotifyTag() {
      this.$api.setBusinessPlanDelayNotifyTag({checked: this.checked});
    },
    async getDelayNotifyTag() {
      let {checked} = await this.$api.getBusinessPlanDelayNotifyTag();
      this.checked = checked
      if (checked) {
        //标记今天不弹窗
        sessionStorage.setItem('delay_notified', 'Y')
        // this.$notify.info('今天不在提醒')
      } else {
        sessionStorage.setItem('delay_notified', 'N')
      }
    },
    showGroupDelay() {
      this.pagingData.page = 1
      this.getDelayList()
    },
    defaultDept( group ) {
      // console.log('g', group)
      this.searchCondition.group_id = group.id
      this.searchCondition.group_name = group.name
      this.getDelayList()
    },
    selectDept( val, labelArr ) {
      let group_id = null;
      let group_name = null;
      // this.searchCondition.depth = null
      if (val && val.length > 1) {
        // this.searchCondition.depth = val.length
        group_id = val[val.length - 1];
        group_name = labelArr[val.length - 1];
      }

      this.searchCondition.group_id = group_id
      this.searchCondition.group_name = group_name
      this.getDelayList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.done_status) {
        condition.done_status = this.searchCondition.done_status
      }

      if (this.searchCondition.release_month) {
        condition.release_month = this.searchCondition.release_month
      }

      if (this.searchCondition.ym) {
        // console.log('ym',this.searchCondition.ym)
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0);
        let daycount = day.getDate();//最后一天

        // this.searchCondition.time_value = [ym + '-01', ym + '-' + daycount]
        condition.start_time = ym + '-01'
        condition.end_time = ym + '-' + daycount
      }

      return condition
    },
    changeTableSort( {prop, order} ) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getDelayList()
    },

    async getDelayList() {
      this.loadingStatus = true
      let pagingInfo = {current_page: this.pagingData.page, page_size: this.pagingData.page_size}
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo, {order: this.orderSort})
      let {list, pages} = await this.$api.getBusinessPlanDelayList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.total = pages.total
      // this.pagingData. = pages.total
      // this.offset = pages.offset
      this.loadingStatus = false

    },
    execStep( row ) {
      this.$emit('execStep', row)
    },
    async startNotify() {
      if (this.auto && this.userPermissions.indexOf('business_manage_delay_notify') > -1) {
        // this.$notify.success('有权限查看预期')
        let delay_notified = sessionStorage.getItem('delay_notified') ? sessionStorage.getItem('delay_notified') : 'N'
        if (delay_notified !== 'Y') {
          this.open()
        } else {
          // this.$notify.warning('已过弹窗')
          // this.open()
        }

        // else {
        //   this.$notify.warning('已过弹窗')
        // }
      }
    }
  },
  // activated() {
  //   if (!this.runTag)
  //     this.runTag = setInterval(this.startNotify, 5000)
  // },
  // deactivated() {
  //   if (this.runTag) {
  //     clearInterval(this.runTag)
  //     this.runTag = null
  //   }
  // },
  mounted() {
    this.getDelayNotifyTag()
    if (!this.runTag)
      this.runTag = setInterval(this.startNotify, 2000)
  },
  beforeDestroy() {
    if (this.runTag) {
      clearInterval(this.runTag)
      this.runTag = null
    }
  }
}
</script>

<style scoped>
.el-dialog__body {
  padding: 4px 24px;
  word-wrap: break-word
}

</style>
<style>
.el-dialog__header {
  padding: 10px 20px 10px 20px !important;
}

.el-dialog__body {
  padding: 10px 24px !important;
  word-wrap: break-word
}

</style>
